import React, { FC } from "react"
import styled from "styled-components"

const H3 = styled.h3`
  margin-bottom: 6px;
  margin-top: 0;
  font-weight: normal;
`
interface StatisticsModalProps {}

const StatisticsModal: FC<StatisticsModalProps> = () => {
  return (
    <>
      <h2>Statistics:</h2>
      <H3>{`>`} Total Runtime: 653h</H3>
      <H3>{`>`} Last Cleaning: 48h</H3>
      <H3>{`>`} Total Revenue: 2045,12 EUR</H3>
    </>
  )
}

export default StatisticsModal
