import styled from 'styled-components'

export const Button = styled.button`
  border: none;
  padding: 14px;
  border-radius: 15px;
  width: 100%;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
  color: black;
  font-weight: bold;
`
