import React, { FC, useState } from "react"
import styled from "styled-components"
import { Button } from "./components/Button"
import BottlesModal from "./components/modals/BottlesModal"
import StatisticsModal from "./components/modals/StatisticsModal"
import TemperatureModal from "./components/modals/TemperatureModal"

const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.67);
  top: 0;
  left: 0;
`

const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f5f5f5;
  border: 1px solid white;
  padding: 36px;
  background-color: rgba(0, 0, 0, 0.67);
  border-radius: 15px;
`

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  transform: translateY(70%);
  transition: all 0.2s ease-in-out;
  :hover {
    transform: translateY(0);
  }
`

const H1 = styled.h1`
  color: #9cdc0e;
`

const Container = styled.div`
  bottom: min(5%, 64px);
  margin-left: auto;
  margin-right: auto;
  width: min(768px, 90%);
  margin-bottom: min(64px, 5%);
  background-color: rgba(0, 0, 0, 0.64);
  background: linear-gradient(rgba(0, 0, 0, 0.67), rgba(0, 0, 0, 1));
  border-radius: 15px;
  padding: 12px;
  color: white;
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`

const MyButton = styled(Button)`
  width: auto;
  min-width: 150px;
  margin-left: 14px;
`

interface OverlayProps {
  onOpenDoor: () => void
}

const Overlay: FC<OverlayProps> = ({ onOpenDoor }) => {
  const [modal, setModal] = useState<"none" | "temperature" | "bottles" | "statistics">("none")
  const [temperature, setTemperature] = useState<number>(10)

  return (
    <>
      {modal !== "none" ? (
        <>
          <ModalContainer onClick={() => setModal("none")}></ModalContainer>
          <Modal>
            {modal === "temperature" && (
              <TemperatureModal
                temperature={temperature}
                setTemperature={(val) => setTemperature(parseInt(val, 10))}
              />
            )}
            {modal === "bottles" && <BottlesModal />}
            {modal === "statistics" && <StatisticsModal />}
          </Modal>
        </>
      ) : null}
      <Wrapper>
        <Container>
          <H1>My Limifyze Machine</H1>
          <Flex>
            <MyButton
              onClick={() => {
                setModal("temperature")
              }}
            >
              Temperature
            </MyButton>
            <MyButton onClick={() => setModal("bottles")}>Bottles</MyButton>
            <MyButton onClick={() => setModal("statistics")}>Statistics</MyButton>
          </Flex>
          <Button onClick={onOpenDoor}>Open Door</Button>
        </Container>
      </Wrapper>
    </>
  )
}

export default Overlay
