import React, { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
export interface IInputProps {
  type: 'text' | 'number' | 'date' | 'password' | 'textarea'
  id?: string
  value?: string
  placeholder?: string
  disabled?: boolean
  fontSize?: number
  rows?: number
  label?: string
  transparent?: boolean
  maxWidth?: string
  onChange?: (value: string, name?: string) => void
  onFocus?: () => void
}

export const StyledInput = styled.input<
  {
    transparent?: boolean
    fontSize?: number
    maxWidth?: string
  } &
    React.HTMLProps<HTMLInputElement>
>`
  border-radius: 4px;
  border-color: white;
  border-width: 1px;
  font-size: 1em;
  line-height: 1.5em;
  background-color: transparent;
  color: white;
  width: auto;
`

export const Input: FC<IInputProps> = ({
  type,
  value,
  maxWidth,
  rows,
  id,
  transparent = false,
  placeholder = '',
  disabled = false,
  fontSize,
  label,
  onChange = () => {},
  onFocus = () => {},
  ...props
}) => {
  const [state, setState] = useState<string>(`${value}`)
  const inputRef = useRef<HTMLInputElement>(null)


  return (
    <>
      {label && <label>{label}</label>}
      <StyledInput
        ref={inputRef}
        as={type === 'textarea' ? 'textarea' : 'input'}
        id={id}
        transparent={transparent}
        maxWidth={maxWidth}
        disabled={disabled}
        type={type}
        value={state}
        placeholder={placeholder}
        fontSize={fontSize}
        onChange={
          disabled
            ? () => {}
            : (ev: any) => {
                setState(ev.target.value)
                onChange(ev.target.value, ev.target.id)
              }
        }
        {...props}
      />
    </>
  )
}