/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { GLTF } from "three-stdlib"

type GLTFResult = GLTF & {
  nodes: {
    mesh_0: THREE.Mesh
    mesh_1: THREE.Mesh
    mesh_2: THREE.Mesh
    mesh_3: THREE.Mesh
    mesh_4: THREE.Mesh
    mesh_5: THREE.Mesh
    mesh_6: THREE.Mesh
    mesh_7: THREE.Mesh
    mesh_8: THREE.Mesh
  }
  materials: {}
}

export default function Turntable({
  ...props
}: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF(
    "/models/Limifyze_Digital_Turntable.gltf"
  ) as GLTFResult
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} />
      <mesh geometry={nodes.mesh_1.geometry} material={nodes.mesh_1.material} />
      <mesh geometry={nodes.mesh_2.geometry} material={nodes.mesh_2.material} />
      <mesh geometry={nodes.mesh_3.geometry} material={nodes.mesh_3.material} />
      <mesh geometry={nodes.mesh_4.geometry} material={nodes.mesh_4.material} />
      <mesh geometry={nodes.mesh_5.geometry} material={nodes.mesh_5.material} />
      <mesh geometry={nodes.mesh_6.geometry} material={nodes.mesh_6.material} />
      <mesh geometry={nodes.mesh_7.geometry} material={nodes.mesh_7.material} />
      <mesh geometry={nodes.mesh_8.geometry} material={nodes.mesh_8.material} />
    </group>
  )
}

useGLTF.preload("/Limifyze_Digital_Turntable.gltf")
