import React, { FC } from "react"
import {
  BoxGeometry,
  Euler,
  ImageUtils,
  MeshBasicMaterial,
  MeshPhongMaterial,
  PlaneGeometry,
  TextureLoader,
  Vector3,
} from "three"
import Door from "./3d/Door/Door"
import Lightframe from "./3d/Lightframe"
import IndoorLights from "./3d/Lights/IndoorLights"
import PayterWindow from "./3d/PayterWindow"
import Turntable from "./3d/Turntable"
import TwinMachine from "./3d/TwinMachine"
import Window from "./3d/Window"

interface MachineProps {
  isDoorOpen: boolean
}

const Machine: FC<MachineProps> = ({ isDoorOpen }) => {
  return (
    <>
      <spotLight
        args={[0xffffff, 5, 1000]}
        position={[-3, 7, 3]}
        color={0xffffff}
        intensity={1}
        angle={3}
        penumbra={0.5}
        castShadow
      />
      <spotLight
        position={[3, 7, 3]}
        args={[0xffffff, 5, 1000]}
        intensity={1}
        angle={3}
        penumbra={0.5}
        castShadow
      />
      <ambientLight color={0xebebeb} intensity={0.3} />
      <TwinMachine
        castShadow
        receiveShadow
        rotation={new Euler(-Math.PI / 2, 0, Math.PI / 2)}
        scale={new Vector3(3, 3, 3)}
      />

      <IndoorLights />

      <Window
        rotation={new Euler(-Math.PI / 2, 0, Math.PI / 2)}
        scale={new Vector3(3, 3, 3)}
      />

      <Lightframe
        position={new Vector3(0, 0, 0)}
        scale={new Vector3(3, 3, 3)}
        rotation={new Euler(-Math.PI / 2, 0, Math.PI / 2)}
      />

      <Turntable
        castShadow
        receiveShadow
        rotation={new Euler(-Math.PI / 2, 0, Math.PI / 2)}
        scale={new Vector3(0.03, 0.03, 0.03)}
      />

      <PayterWindow
        rotation={new Euler(-Math.PI / 2, 0, Math.PI / 2)}
        scale={new Vector3(3, 3, 3)}
      />

      <Door isDoorOpen={isDoorOpen} />

      <mesh
        args={[
          new PlaneGeometry(0.5, 0.3),
          new MeshBasicMaterial({
            map: new TextureLoader().load("/Welcome.jpg"),
          }),
        ]}
        position={new Vector3(0, 0.925, 0.73)}
        rotation={new Euler(-0.4, 0, 0)}
      />

      <fog attach="fog" args={[0xa0a0a0, 10, 40]} />
      <mesh
        receiveShadow
        rotation={new Euler(-Math.PI / 2, 0, 0)}
        position={new Vector3(0, -0.52, 0)}
        geometry={new BoxGeometry(40, 60)}
        material={new MeshPhongMaterial({ color: 0xdcdcdc, depthWrite: false })}
      />
      <gridHelper args={[300, 200, 0x000000, 0x000000]} />
    </>
  )
}

export default Machine
