import { extend, ReactThreeFiber, useThree } from "@react-three/fiber"
import React, { useEffect, useRef, useState } from "react"
import { Color, Vector3 } from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

extend({ OrbitControls })

declare global {
  namespace JSX {
    interface IntrinsicElements {
      orbitControls: ReactThreeFiber.Object3DNode<
        OrbitControls,
        typeof OrbitControls
      >
    }
  }
}

const cameraLookat = {
  x: 0,
  y: .7,
  z: 0.4,
}

const CameraControls = () => {
  const controls = useRef<OrbitControls>()

  const {
    camera,
    gl: { domElement },
    scene,
  } = useThree()

  useEffect(() => {
    camera.lookAt(cameraLookat.x, cameraLookat.y, cameraLookat.z)
  }, [camera])

  scene.background = new Color(0xa0a0a0)

  camera.position.x = -1
  camera.position.y = 2

  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      enableZoom
      target={new Vector3(cameraLookat.x, cameraLookat.y, cameraLookat.z)}
    />
  )
}

export default CameraControls
