import React, { FC } from "react"
import styled from "styled-components"

const H3 = styled.h3`
  margin-bottom: 6px;
  margin-top: 0;
  font-weight: normal;
`
interface BottlesModalProps {}

const BottlesModal: FC<BottlesModalProps> = () => {
  return (
    <>
      <h2>Bottles:</h2>
      <H3>{`>`} Bottle 1 – 1.00 liters</H3>
      <H3>{`>`} Bottle 2 – 1.20 liters</H3>
      <H3>{`>`} Bottle 3 – 1.40 liters</H3>
      <H3>{`>`} Bottle 4 – 0.90 liters</H3>
      <H3>{`>`} Bottle 5 – 1.10 liters</H3>
      <H3>{`>`} Bottle 6 – 1.00 liters</H3>
    </>
  )
}

export default BottlesModal
