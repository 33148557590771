import React, { FC } from "react"
import { Input } from "../Input"

interface TemperatureModalProps {
  temperature: number
  setTemperature: (val: string) => void
}

const TemperatureModal: FC<TemperatureModalProps> = ({
  temperature,
  setTemperature,
}) => {
  return (
    <>
      <h2>Current Temperature: 12°C</h2>
      <h2>Goal Temperature:</h2>
      <Input
        type="number"
        value={temperature.toString()}
        onChange={(val) => setTemperature(val)}
      />
      °C
    </>
  )
}

export default TemperatureModal
