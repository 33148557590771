import React, { FC, useEffect, useRef } from "react"
import { useThree } from "@react-three/fiber"
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper"
import { Euler, Vector3 } from "three"

const IndoorLights: FC = () => {
  const ref = useRef<THREE.RectAreaLight>(null)
  const ref2 = useRef<THREE.RectAreaLight>(null)
  const ref3 = useRef<THREE.RectAreaLight>(null)
  const { scene } = useThree()

  useEffect(() => {
    if (ref.current) {
      scene.add(new RectAreaLightHelper(ref.current))
    }
    if (ref2.current) {
      scene.add(new RectAreaLightHelper(ref2.current))
    }
    // if (ref3.current) {
    //   scene.add(new RectAreaLightHelper(ref3.current))
    // }
  }, [scene, ref, ref2, ref3])

  return (
    <>
      <rectAreaLight
        ref={ref}
        color={0xff0000}
        width={0.05}
        height={1.2}
        rotation={new Euler(0, Math.PI / 2, 0)}
        intensity={50}
        position={new Vector3(0.5, 1.6, 0.28)}
      />
      <rectAreaLight
        ref={ref2}
        color={0x0000ff}
        width={1}
        height={0.05}
        rotation={new Euler(Math.PI / 2 - 0.5, 0, 0)}
        intensity={50}
        position={new Vector3(-0.05, 1.15, 0.5)}
      />
      <rectAreaLight
        ref={ref3}
        color={0xffffff}
        width={0.4}
        height={0.23}
        rotation={new Euler(-0.4, 0, 0)}
        intensity={40}
        position={new Vector3(0, 0.92, 0.758)}
      />
    </>
  )
}

export default IndoorLights
