import { useThree } from "@react-three/fiber"
import React, { FC } from "react"
import { Euler, Vector3 } from "three"
import DoorModel from "./DoorModel"

interface DoorProps {
  isDoorOpen: boolean
}

const Door: FC<DoorProps> = ({ isDoorOpen }) => {
  const { camera } = useThree()
  // gltf_door.scene.scale.set(0.03, 0.03, 0.03)

  let pos: Vector3
  let rot: Euler
  if (isDoorOpen) {
    camera.position.x = -1
    camera.position.y = 2
    camera.position.z = 3
    camera.lookAt(0, 0.7, 0.4)
    rot = new Euler(-Math.PI / 2, 0, Math.PI)
    pos = new Vector3(0, 0, 1.1)
  } else {
    camera.position.x = -1
    camera.position.y = 2
    camera.position.z = 3
    camera.lookAt(0, 0.7, 0.4)
    rot = new Euler(-Math.PI / 2, 0, Math.PI / 2)
    pos = new Vector3(0, 0, 0)
  }

  return (
    <DoorModel
      castShadow
      receiveShadow
      position={pos}
      scale={new Vector3(0.03, 0.03, 0.03)}
      rotation={rot}
    />
  )
}

export default Door
