/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { GLTF } from "three-stdlib"
import { MeshPhysicalMaterial } from "three"

type GLTFResult = GLTF & {
  nodes: {
    mesh_0: THREE.Mesh
    mesh_1: THREE.Mesh
    mesh_2: THREE.Mesh
    mesh_3: THREE.Mesh
    mesh_4: THREE.Mesh
    mesh_5: THREE.Mesh
    mesh_6: THREE.Mesh
    mesh_7: THREE.Mesh
    mesh_8: THREE.Mesh
    mesh_9: THREE.Mesh
    mesh_10: THREE.Mesh
    mesh_11: THREE.Mesh
    mesh_12: THREE.Mesh
    mesh_13: THREE.Mesh
    mesh_14: THREE.Mesh
    mesh_15: THREE.Mesh
    mesh_16: THREE.Mesh
    mesh_17: THREE.Mesh
    mesh_18: THREE.Mesh
    mesh_19: THREE.Mesh
    mesh_20: THREE.Mesh
    mesh_21: THREE.Mesh
    mesh_22: THREE.Mesh
    mesh_23: THREE.Mesh
    mesh_24: THREE.Mesh
    mesh_25: THREE.Mesh
    mesh_26: THREE.Mesh
    mesh_27: THREE.Mesh
    mesh_28: THREE.Mesh
    mesh_29: THREE.Mesh
    mesh_30: THREE.Mesh
    mesh_31: THREE.Mesh
    mesh_32: THREE.Mesh
    mesh_33: THREE.Mesh
    mesh_34: THREE.Mesh
    mesh_35: THREE.Mesh
    mesh_36: THREE.Mesh
    mesh_37: THREE.Mesh
    mesh_38: THREE.Mesh
    mesh_39: THREE.Mesh
    mesh_40: THREE.Mesh
    mesh_41: THREE.Mesh
    mesh_42: THREE.Mesh
    mesh_43: THREE.Mesh
    mesh_44: THREE.Mesh
    mesh_45: THREE.Mesh
    mesh_46: THREE.Mesh
    mesh_47: THREE.Mesh
    mesh_48: THREE.Mesh
    mesh_49: THREE.Mesh
    mesh_50: THREE.Mesh
    mesh_51: THREE.Mesh
    mesh_52: THREE.Mesh
    mesh_53: THREE.Mesh
    mesh_54: THREE.Mesh
    mesh_55: THREE.Mesh
    mesh_56: THREE.Mesh
    mesh_57: THREE.Mesh
    mesh_58: THREE.Mesh
    mesh_59: THREE.Mesh
    mesh_60: THREE.Mesh
    mesh_61: THREE.Mesh
    mesh_62: THREE.Mesh
    mesh_63: THREE.Mesh
    mesh_64: THREE.Mesh
    mesh_65: THREE.Mesh
    mesh_66: THREE.Mesh
    mesh_67: THREE.Mesh
    mesh_68: THREE.Mesh
    mesh_69: THREE.Mesh
    mesh_70: THREE.Mesh
    mesh_71: THREE.Mesh
    mesh_72: THREE.Mesh
    mesh_73: THREE.Mesh
    mesh_74: THREE.Mesh
    mesh_75: THREE.Mesh
    mesh_76: THREE.Mesh
    mesh_77: THREE.Mesh
    mesh_78: THREE.Mesh
    mesh_79: THREE.Mesh
    mesh_80: THREE.Mesh
    mesh_81: THREE.Mesh
    mesh_82: THREE.Mesh
    mesh_83: THREE.Mesh
    mesh_84: THREE.Mesh
    mesh_85: THREE.Mesh
    mesh_86: THREE.Mesh
    mesh_87: THREE.Mesh
    mesh_88: THREE.Mesh
    mesh_89: THREE.Mesh
    mesh_90: THREE.Mesh
    mesh_91: THREE.Mesh
    mesh_92: THREE.Mesh
    mesh_93: THREE.Mesh
    mesh_94: THREE.Mesh
    mesh_95: THREE.Mesh
    mesh_96: THREE.Mesh
    mesh_97: THREE.Mesh
    mesh_98: THREE.Mesh
    mesh_99: THREE.Mesh
    mesh_100: THREE.Mesh
    mesh_101: THREE.Mesh
    mesh_102: THREE.Mesh
    mesh_103: THREE.Mesh
    mesh_104: THREE.Mesh
    mesh_105: THREE.Mesh
    mesh_106: THREE.Mesh
    mesh_107: THREE.Mesh
    mesh_108: THREE.Mesh
    mesh_109: THREE.Mesh
    mesh_110: THREE.Mesh
    mesh_111: THREE.Mesh
    mesh_112: THREE.Mesh
    mesh_113: THREE.Mesh
    mesh_114: THREE.Mesh
    mesh_115: THREE.Mesh
    mesh_116: THREE.Mesh
    mesh_117: THREE.Mesh
    mesh_118: THREE.Mesh
    mesh_119: THREE.Mesh
    mesh_120: THREE.Mesh
    mesh_121: THREE.Mesh
    mesh_122: THREE.Mesh
    mesh_123: THREE.Mesh
    mesh_124: THREE.Mesh
    mesh_125: THREE.Mesh
    mesh_126: THREE.Mesh
    mesh_127: THREE.Mesh
    mesh_128: THREE.Mesh
    mesh_129: THREE.Mesh
    mesh_130: THREE.Mesh
    mesh_131: THREE.Mesh
    mesh_132: THREE.Mesh
    mesh_133: THREE.Mesh
    mesh_134: THREE.Mesh
    mesh_135: THREE.Mesh
    mesh_136: THREE.Mesh
    mesh_137: THREE.Mesh
    mesh_138: THREE.Mesh
    mesh_139: THREE.Mesh
    mesh_140: THREE.Mesh
    mesh_141: THREE.Mesh
    mesh_142: THREE.Mesh
    mesh_143: THREE.Mesh
    mesh_144: THREE.Mesh
    mesh_145: THREE.Mesh
    mesh_146: THREE.Mesh
    mesh_147: THREE.Mesh
    mesh_148: THREE.Mesh
    mesh_149: THREE.Mesh
    mesh_150: THREE.Mesh
    mesh_151: THREE.Mesh
    mesh_152: THREE.Mesh
    mesh_153: THREE.Mesh
    mesh_154: THREE.Mesh
    mesh_155: THREE.Mesh
    mesh_156: THREE.Mesh
    mesh_157: THREE.Mesh
    mesh_158: THREE.Mesh
    mesh_159: THREE.Mesh
    mesh_160: THREE.Mesh
    mesh_161: THREE.Mesh
    mesh_162: THREE.Mesh
    mesh_163: THREE.Mesh
    mesh_164: THREE.Mesh
    mesh_165: THREE.Mesh
    mesh_166: THREE.Mesh
    mesh_167: THREE.Mesh
    mesh_168: THREE.Mesh
    mesh_169: THREE.Mesh
    mesh_170: THREE.Mesh
    mesh_171: THREE.Mesh
    mesh_172: THREE.Mesh
    mesh_173: THREE.Mesh
    mesh_174: THREE.Mesh
    mesh_175: THREE.Mesh
    mesh_176: THREE.Mesh
    mesh_177: THREE.Mesh
    mesh_178: THREE.Mesh
    mesh_179: THREE.Mesh
    mesh_180: THREE.Mesh
    mesh_181: THREE.Mesh
    mesh_182: THREE.Mesh
    mesh_183: THREE.Mesh
    mesh_184: THREE.Mesh
    mesh_185: THREE.Mesh
    mesh_186: THREE.Mesh
    mesh_187: THREE.Mesh
    mesh_188: THREE.Mesh
    mesh_189: THREE.Mesh
    mesh_190: THREE.Mesh
    mesh_191: THREE.Mesh
    mesh_192: THREE.Mesh
    mesh_193: THREE.Mesh
    mesh_194: THREE.Mesh
    mesh_195: THREE.Mesh
    mesh_196: THREE.Mesh
    mesh_197: THREE.Mesh
    mesh_198: THREE.Mesh
    mesh_199: THREE.Mesh
    mesh_200: THREE.Mesh
    mesh_201: THREE.Mesh
    mesh_202: THREE.Mesh
    mesh_203: THREE.Mesh
    mesh_204: THREE.Mesh
    mesh_205: THREE.Mesh
    mesh_206: THREE.Mesh
    mesh_207: THREE.Mesh
    mesh_208: THREE.Mesh
    mesh_209: THREE.Mesh
    mesh_210: THREE.Mesh
    mesh_211: THREE.Mesh
    mesh_212: THREE.Mesh
    mesh_213: THREE.Mesh
    mesh_214: THREE.Mesh
    mesh_215: THREE.Mesh
    mesh_216: THREE.Mesh
    mesh_217: THREE.Mesh
    mesh_218: THREE.Mesh
    mesh_219: THREE.Mesh
    mesh_220: THREE.Mesh
    mesh_221: THREE.Mesh
    mesh_222: THREE.Mesh
    mesh_223: THREE.Mesh
    mesh_224: THREE.Mesh
    mesh_225: THREE.Mesh
    mesh_226: THREE.Mesh
    mesh_227: THREE.Mesh
    mesh_228: THREE.Mesh
    mesh_229: THREE.Mesh
    mesh_230: THREE.Mesh
    mesh_231: THREE.Mesh
    mesh_232: THREE.Mesh
    mesh_233: THREE.Mesh
    mesh_234: THREE.Mesh
    mesh_235: THREE.Mesh
    mesh_236: THREE.Mesh
    mesh_237: THREE.Mesh
    mesh_238: THREE.Mesh
    mesh_239: THREE.Mesh
    mesh_240: THREE.Mesh
    mesh_241: THREE.Mesh
    mesh_242: THREE.Mesh
    mesh_243: THREE.Mesh
    mesh_244: THREE.Mesh
    mesh_245: THREE.Mesh
    mesh_246: THREE.Mesh
    mesh_247: THREE.Mesh
    mesh_248: THREE.Mesh
    mesh_249: THREE.Mesh
    mesh_250: THREE.Mesh
    mesh_251: THREE.Mesh
    mesh_252: THREE.Mesh
    mesh_253: THREE.Mesh
    mesh_254: THREE.Mesh
    mesh_255: THREE.Mesh
    mesh_256: THREE.Mesh
    mesh_257: THREE.Mesh
    mesh_258: THREE.Mesh
    mesh_259: THREE.Mesh
    mesh_260: THREE.Mesh
    mesh_261: THREE.Mesh
    mesh_262: THREE.Mesh
    mesh_263: THREE.Mesh
    mesh_264: THREE.Mesh
    mesh_265: THREE.Mesh
    mesh_266: THREE.Mesh
    mesh_267: THREE.Mesh
    mesh_268: THREE.Mesh
    mesh_269: THREE.Mesh
    mesh_270: THREE.Mesh
    mesh_271: THREE.Mesh
    mesh_272: THREE.Mesh
    mesh_273: THREE.Mesh
    mesh_274: THREE.Mesh
    mesh_275: THREE.Mesh
    mesh_276: THREE.Mesh
  }
  materials: {
    ["Kunststoff - glänzend (Schwarz)"]: THREE.MeshStandardMaterial
    ["Pulverbeschichtung (Schwarz)"]: THREE.MeshStandardMaterial
    ["Kunststoff - matt (Schwarz)"]: THREE.MeshStandardMaterial
    ["Opaque(64,64,64)"]: THREE.MeshStandardMaterial
    ["Aluminium - satiniert"]: THREE.MeshStandardMaterial
    ["ABS (Weiß)"]: THREE.MeshStandardMaterial
    ["Edelstahl - satiniert"]: THREE.MeshStandardMaterial
    ["Aluminium - eloxiert, glänzend (Grau)"]: THREE.MeshStandardMaterial
  }
}

export default function TwinMachine({
  ...props
}: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF(
    "/models/Limifyze_Digital_Twin_excl.gltf"
  ) as GLTFResult

  const metalMaterial = new MeshPhysicalMaterial({
    color: 0x2f3234,
    roughness: 1,
    metalness: 0.5,
    reflectivity: 0,
    clearcoat: 0,
    clearcoatRoughness: 0,
  })
  
  const metalStatiniertMaterial = new MeshPhysicalMaterial({
    color: 0xb0b0b0,
    roughness: 0.5,
    metalness: 0.7,
    reflectivity: 0,
    clearcoat: 0,
    clearcoatRoughness: 0,
  })
  
  const plasticGloss = new MeshPhysicalMaterial({
    color: 0x000000,
    roughness: 0.18,
    metalness: 0.7,
    reflectivity: 0,
    clearcoat: 0,
    clearcoatRoughness: 0,
  })

  const opaque = metalMaterial

  const plasticMat = metalMaterial

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              castShadow
              geometry={nodes.mesh_0.geometry}
              material={plasticGloss}
            />
          </group>
          <group position={[0.28, 0.12, 1.05]}>
            <group
              position={[-3.13, -0.12, 14.15]}
              rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_1.geometry}
                material={metalMaterial}
              />
            </group>
            <group
              position={[-18.78, -0.12, 30.28]}
              rotation={[Math.PI / 2, Math.PI / 2, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_2.geometry}
                material={metalMaterial}
              />
            </group>
          </group>
          <group
            position={[-18.5, 0, 18.15]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
          >
            <mesh
              castShadow
              geometry={nodes.mesh_3.geometry}
              material={plasticGloss}
            />
          </group>
          <group
            position={[-19.7, -14.7, 22.88]}
            rotation={[0, 0, Math.PI / 2]}
          />
          <group position={[-18.5, 0, 13.98]} rotation={[0, 0, Math.PI / 2]}>
            <mesh
              castShadow
              geometry={nodes.mesh_4.geometry}
              material={plasticMat}
            />
          </group>
          <group position={[-19.7, 0, 13.98]} rotation={[0, 0, Math.PI / 2]} />
          <group
            position={[-2.95, -23.98, 24.45]}
            rotation={[Math.PI, 0, -Math.PI]}
          >
            <group
              position={[-3.1, 23.98, 24.58]}
              rotation={[-Math.PI, 0, Math.PI]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_5.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[-3.1, 23.98, 24.58]}
              rotation={[-Math.PI, 0, Math.PI]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_6.geometry}
                material={opaque}
              />
            </group>
            <group position={[0.1, 29.5, 0]}>
              <mesh
                castShadow
                geometry={nodes.mesh_7.geometry}
                material={opaque}
              />
            </group>
            <group position={[1, 30.25, 0.17]} rotation={[0, 0, -Math.PI / 3]}>
              <mesh
                castShadow
                geometry={nodes.mesh_8.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[1, 30.25, -0.57]}
              rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_9.geometry}
                material={opaque}
              />
            </group>
            <group position={[1, 30.25, -0.33]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                castShadow
                geometry={nodes.mesh_10.geometry}
                material={opaque}
              />
            </group>
            <group position={[0.1, 16.95, 0]}>
              <mesh
                castShadow
                geometry={nodes.mesh_11.geometry}
                material={opaque}
              />
            </group>
            <group position={[1, 17.7, 0.17]} rotation={[0, 0, -Math.PI / 3]}>
              <mesh
                castShadow
                geometry={nodes.mesh_12.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[1, 17.7, -0.57]}
              rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_13.geometry}
                material={opaque}
              />
            </group>
            <group position={[1, 17.7, -0.33]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                castShadow
                geometry={nodes.mesh_14.geometry}
                material={opaque}
              />
            </group>
            <mesh
              castShadow
              geometry={nodes.mesh_15.geometry}
              material={metalStatiniertMaterial}
            />
            <mesh
              castShadow
              geometry={nodes.mesh_16.geometry}
              material={plasticMat}
            />
          </group>
          <group position={[-11.57, 0, 3.7]} rotation={[0, 0, -Math.PI / 2]}>
            <group
              position={[-0.03, 0.75, 1.85]}
              rotation={[Math.PI / 2, 0, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_17.geometry}
                material={metalStatiniertMaterial}
              />
            </group>
            <group position={[-0.03, 0, 0]}>
              <mesh
                castShadow
                geometry={nodes.mesh_18.geometry}
                material={plasticMat}
              />
            </group>
          </group>
          <group position={[-10.98, 0, 22.9]} rotation={[Math.PI / 2, 0.84, 0]}>
            <mesh
              castShadow
              geometry={nodes.mesh_19.geometry}
              material={plasticMat}
            />
          </group>
          <group
            position={[-20, 0, 30.88]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          >
            <mesh
              castShadow
              geometry={nodes.mesh_20.geometry}
              material={metalMaterial}
            />
            <mesh
              castShadow
              geometry={nodes.mesh_21.geometry}
              material={metalMaterial}
            />
            <mesh
              castShadow
              geometry={nodes.mesh_22.geometry}
              material={metalMaterial}
            />
            <mesh
              castShadow
              geometry={nodes.mesh_23.geometry}
              material={metalMaterial}
            />
            <mesh
              castShadow
              geometry={nodes.mesh_24.geometry}
              material={metalMaterial}
            />
            <mesh
              castShadow
              geometry={nodes.mesh_25.geometry}
              material={metalMaterial}
            />
            <group position={[0, -0.73, 2.49]} rotation={[-Math.PI / 9, 0, 0]}>
              <mesh
                castShadow
                geometry={nodes.mesh_26.geometry}
                material={metalMaterial}
              />
            </group>
            <mesh
              castShadow
              geometry={nodes.mesh_27.geometry}
              material={metalMaterial}
            />
            <group
              position={[1.19, -0.24, 2.99]}
              rotation={[-Math.PI / 9, 0, 0]}
            >
              <group position={[2.74, -0.8, 0.53]}>
                <group position={[-12.98, -5.49, -0.05]}>
                  <mesh
                    castShadow
                    geometry={nodes.mesh_28.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_29.geometry}
                    material={opaque}
                  />
                </group>
                <group position={[-3.79, 0.31, -0.08]}>
                  <mesh
                    castShadow
                    geometry={nodes.mesh_30.geometry}
                    material={opaque}
                  />
                </group>
                <group position={[-3.79, 0.31, -0.13]}>
                  <mesh
                    castShadow
                    geometry={nodes.mesh_31.geometry}
                    material={opaque}
                  />
                </group>
                <group position={[-1.54, -11.49, -0.11]}>
                  <mesh
                    castShadow
                    geometry={nodes.mesh_32.geometry}
                    material={opaque}
                  />
                </group>
                <group
                  position={[5.34, -5.67, -0.05]}
                  rotation={[-Math.PI, 0, Math.PI]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_33.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_34.geometry}
                    material={opaque}
                  />
                </group>
                <group
                  position={[4.46, -4.68, -0.13]}
                  rotation={[-Math.PI, 0, Math.PI]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_35.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_36.geometry}
                    material={opaque}
                  />
                </group>
              </group>
              <group position={[1.79, -1.36, 0.05]}>
                <group position={[-11.13, -4.24, -0.29]}>
                  <mesh
                    castShadow
                    geometry={nodes.mesh_37.geometry}
                    material={opaque}
                  />
                </group>
                <group position={[-4.62, -12.22, 0.21]}>
                  <mesh
                    castShadow
                    geometry={nodes.mesh_38.geometry}
                    material={opaque}
                  />
                </group>
              </group>
              <group
                position={[-0.56, 7.38, -15.09]}
                rotation={[-Math.PI, 0, Math.PI]}
              >
                <group position={[-7.11, -10.21, -14.46]}>
                  <mesh
                    castShadow
                    geometry={nodes.mesh_39.geometry}
                    material={opaque}
                  />
                </group>
                <group position={[-3, -7.37, -14.25]}>
                  <group position={[0.32, -0.08, 0]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_40.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.22, 0.03, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_41.geometry}
                      material={opaque}
                    />
                  </group>
                  <group
                    position={[0, 0.13, 0.01]}
                    rotation={[-Math.PI / 2, 0, Math.PI]}
                  >
                    <mesh
                      castShadow
                      geometry={nodes.mesh_42.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.32, -0.08, 0]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_43.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.17, 0.03, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_44.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.13, 0.03, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_45.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.08, 0.03, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_46.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.03, 0.03, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_47.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.03, 0.03, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_48.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.08, 0.03, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_49.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.13, 0.03, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_50.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.17, 0.03, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_51.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.22, 0.03, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_52.geometry}
                      material={opaque}
                    />
                  </group>
                  <mesh
                    castShadow
                    geometry={nodes.mesh_53.geometry}
                    material={opaque}
                  />
                </group>
                <group position={[0.61, -5.76, -14.25]}>
                  <group position={[1.32, -0.07, 0]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_54.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-1.23, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_55.geometry}
                      material={opaque}
                    />
                  </group>
                  <group
                    position={[0, 0.16, 0.01]}
                    rotation={[-Math.PI / 2, 0, Math.PI]}
                  >
                    <mesh
                      castShadow
                      geometry={nodes.mesh_56.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-1.32, -0.07, 0]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_57.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.93, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_58.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.98, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_59.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.88, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_60.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.28, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_61.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[1.13, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_62.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.63, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_63.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[1.02, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_64.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.98, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_65.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.22, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_66.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[1.08, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_67.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.63, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_68.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.57, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_69.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.03, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_70.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.28, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_71.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.73, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_72.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.13, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_73.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.93, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_74.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.17, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_75.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.82, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_76.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.38, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_77.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-1.08, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_78.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.47, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_79.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-1.17, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_80.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.13, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_81.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.32, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_82.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.77, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_83.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.82, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_84.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.88, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_85.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-1.02, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_86.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.32, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_87.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.73, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_88.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.68, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_89.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.22, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_90.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.52, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_91.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.43, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_92.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.52, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_93.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.77, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_94.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.47, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_95.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.08, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_96.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-1.13, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_97.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.43, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_98.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.38, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_99.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.17, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_100.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.08, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_101.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.68, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_102.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[1.23, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_103.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[1.17, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_104.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.03, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_105.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.57, 0.04, -0.02]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_106.geometry}
                      material={opaque}
                    />
                  </group>
                  <mesh
                    castShadow
                    geometry={nodes.mesh_107.geometry}
                    material={opaque}
                  />
                </group>
                <group
                  position={[0.79, -9.97, -14.12]}
                  rotation={[0, 0, -Math.PI]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_108.geometry}
                    material={opaque}
                  />
                </group>
                <group
                  position={[-3.97, -9.97, -14.12]}
                  rotation={[0, 0, -Math.PI]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_109.geometry}
                    material={opaque}
                  />
                </group>
                <group
                  position={[-2.03, -9.97, -14.12]}
                  rotation={[0, 0, -Math.PI]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_110.geometry}
                    material={opaque}
                  />
                </group>
                <group
                  position={[3.29, -8.21, -14.15]}
                  rotation={[Math.PI / 2, 0, Math.PI]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_111.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_112.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_113.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_114.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_115.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_116.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_117.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_118.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_119.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_120.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_121.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_122.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_123.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_124.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_125.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_126.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_127.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_128.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_129.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_130.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_131.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_132.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_133.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_134.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_135.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_136.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_137.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_138.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_139.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_140.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_141.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_142.geometry}
                    material={opaque}
                  />
                </group>
                <group
                  position={[5.29, -7.76, -13.89]}
                  rotation={[0, 0, -Math.PI]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_143.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_144.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_145.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_146.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_147.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_148.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_149.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_150.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_151.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_152.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_153.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_154.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_155.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_156.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_157.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_158.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_159.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_160.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_161.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_162.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_163.geometry}
                    material={opaque}
                  />
                </group>
                <group position={[7.29, -8, -13.75]}>
                  <mesh
                    castShadow
                    geometry={nodes.mesh_164.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_165.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_166.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_167.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_168.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_169.geometry}
                    material={opaque}
                  />
                </group>
                <group position={[-7.14, -10.29, -14.86]}>
                  <mesh
                    castShadow
                    geometry={nodes.mesh_170.geometry}
                    material={opaque}
                  />
                </group>
                <group
                  position={[-1.92, -4.14, -14.3]}
                  rotation={[0, 0, Math.PI / 2]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_171.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_172.geometry}
                    material={opaque}
                  />
                </group>
                <group
                  position={[-0.92, -4.14, -14.3]}
                  rotation={[0, 0, Math.PI / 2]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_173.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_174.geometry}
                    material={opaque}
                  />
                </group>
                <group
                  position={[0.08, -4.14, -14.3]}
                  rotation={[0, 0, Math.PI / 2]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_175.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_176.geometry}
                    material={opaque}
                  />
                </group>
                <group
                  position={[1.08, -4.14, -14.3]}
                  rotation={[0, 0, Math.PI / 2]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_177.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_178.geometry}
                    material={opaque}
                  />
                </group>
                <group
                  position={[2.08, -4.14, -14.3]}
                  rotation={[0, 0, Math.PI / 2]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_179.geometry}
                    material={opaque}
                  />
                  <mesh
                    castShadow
                    geometry={nodes.mesh_180.geometry}
                    material={opaque}
                  />
                </group>
                <group position={[-0.58, -9.28, -13.99]}>
                  <group position={[-0.38, 0, -0.15]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_181.geometry}
                      material={opaque}
                    />
                  </group>
                  <group
                    position={[-0.38, 0, -0.15]}
                    rotation={[0, 0, Math.PI]}
                  >
                    <mesh
                      castShadow
                      geometry={nodes.mesh_182.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.38, -0.04, 0]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_183.geometry}
                      material={opaque}
                    />
                  </group>
                  <group
                    position={[-0.38, -0.04, -0.05]}
                    rotation={[0, 0, Math.PI]}
                  >
                    <mesh
                      castShadow
                      geometry={nodes.mesh_184.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0, 0, 0.06]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_185.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.13, 0, -0.15]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_186.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.13, 0, -0.15]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_187.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.38, 0, -0.15]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_188.geometry}
                      material={opaque}
                    />
                  </group>
                  <group
                    position={[-0.13, 0, -0.15]}
                    rotation={[0, 0, Math.PI]}
                  >
                    <mesh
                      castShadow
                      geometry={nodes.mesh_189.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.13, 0, -0.15]} rotation={[0, 0, Math.PI]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_190.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.38, 0, -0.15]} rotation={[0, 0, Math.PI]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_191.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[-0.13, -0.04, 0]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_192.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.13, -0.04, 0]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_193.geometry}
                      material={opaque}
                    />
                  </group>
                  <group position={[0.38, -0.04, 0]}>
                    <mesh
                      castShadow
                      geometry={nodes.mesh_194.geometry}
                      material={opaque}
                    />
                  </group>
                  <group
                    position={[-0.13, -0.04, -0.05]}
                    rotation={[0, 0, Math.PI]}
                  >
                    <mesh
                      castShadow
                      geometry={nodes.mesh_195.geometry}
                      material={opaque}
                    />
                  </group>
                  <group
                    position={[0.13, -0.04, -0.05]}
                    rotation={[0, 0, Math.PI]}
                  >
                    <mesh
                      castShadow
                      geometry={nodes.mesh_196.geometry}
                      material={opaque}
                    />
                  </group>
                  <group
                    position={[0.38, -0.04, -0.05]}
                    rotation={[0, 0, Math.PI]}
                  >
                    <mesh
                      castShadow
                      geometry={nodes.mesh_197.geometry}
                      material={opaque}
                    />
                  </group>
                  <mesh
                    castShadow
                    geometry={nodes.mesh_198.geometry}
                    material={opaque}
                  />
                </group>
                <group
                  position={[-5.56, -9.97, -14.12]}
                  rotation={[0, 0, -Math.PI]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_199.geometry}
                    material={opaque}
                  />
                </group>
              </group>
            </group>
            <group position={[0, -0.35, 3.53]} rotation={[2.79, 0, 0]}>
              <mesh
                castShadow
                geometry={nodes.mesh_200.geometry}
                material={plasticMat}
              />
            </group>
          </group>
          <group
            position={[13.45, 0, 8.02]}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
          >
            <group
              position={[-11.5, 2.9, 6.45]}
              rotation={[-Math.PI / 2, 0, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_201.geometry}
                material={plasticMat}
              />
            </group>
            <group
              position={[-11.5, 4.86, 5.75]}
              rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_202.geometry}
                material={opaque}
              />
            </group>
            <group position={[0, 2.38, 6.75]}>
              <mesh
                castShadow
                geometry={nodes.mesh_203.geometry}
                material={plasticMat}
              />
            </group>
            <group
              position={[-18.45, 8.98, 6.7]}
              rotation={[-Math.PI / 2, Math.PI / 6, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_204.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[-11.5, 0.88, 5.75]}
              rotation={[Math.PI / 2, -Math.PI / 6, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_205.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[-18.45, -0.82, 6.7]}
              rotation={[-Math.PI / 2, Math.PI / 6, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_206.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[0, 8.98, 6.7]}
              rotation={[-Math.PI / 2, Math.PI / 6, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_207.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[0, -0.82, 6.7]}
              rotation={[-Math.PI / 2, Math.PI / 6, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_208.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[18.45, 8.98, 6.7]}
              rotation={[-Math.PI / 2, Math.PI / 6, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_209.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[18.45, -0.82, 6.7]}
              rotation={[-Math.PI / 2, Math.PI / 6, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_210.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[0, 4.07, 6.55]}
              rotation={[-Math.PI / 2, Math.PI / 2, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_211.geometry}
                material={metalMaterial}
              />
            </group>
          </group>
          <group
            position={[19.9, 0, 18.4]}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
          >
            <group position={[16.9, -14.25, 0]} rotation={[-Math.PI / 2, 0, 0]}>
              <mesh
                castShadow
                geometry={nodes.mesh_212.geometry}
                material={metalMaterial}
              />
            </group>
            <group
              position={[0.05, -14.25, 0]}
              rotation={[-Math.PI / 2, 0.29, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_213.geometry}
                material={metalMaterial}
              />
            </group>
            <group
              position={[-16.9, -14.25, 0]}
              rotation={[-Math.PI / 2, 0, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_214.geometry}
                material={metalMaterial}
              />
            </group>
            <group
              position={[19.9, -14.25, -36.7]}
              rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_215.geometry}
                material={metalMaterial}
              />
            </group>
            <group
              position={[-19.9, -14.25, -36.7]}
              rotation={[-1.68, 0, -Math.PI / 2]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_216.geometry}
                material={metalMaterial}
              />
            </group>
            <group
              position={[19.9, 12.2, -36.7]}
              rotation={[3.09, 0, Math.PI / 2]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_217.geometry}
                material={metalMaterial}
              />
            </group>
            <group
              position={[-19.9, 12.2, -36.7]}
              rotation={[-1.2, 0, -Math.PI / 2]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_218.geometry}
                material={metalMaterial}
              />
            </group>
            <group
              position={[16.9, 8.1, 0]}
              rotation={[Math.PI / 2, 1.05, -Math.PI]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_219.geometry}
                material={metalMaterial}
              />
            </group>
            <group
              position={[-16.9, 8.1, 0]}
              rotation={[Math.PI / 2, -0.94, -Math.PI]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_220.geometry}
                material={metalMaterial}
              />
            </group>
            <group position={[0, 8.1, 0]} rotation={[-Math.PI / 2, -1.37, 0]}>
              <mesh
                castShadow
                geometry={nodes.mesh_221.geometry}
                material={metalMaterial}
              />
            </group>
            <mesh
              castShadow
              geometry={nodes.mesh_222.geometry}
              material={metalMaterial}
            />
          </group>
          <group
            position={[0.03, 0, 33.8]}
            rotation={[Math.PI, 0, Math.PI / 2]}
          >
            <group position={[0, -0.68, 0.4]} rotation={[-Math.PI / 2, 0, 0]}>
              <mesh
                castShadow
                geometry={nodes.mesh_223.geometry}
                material={plasticMat}
              />
            </group>
            <group
              position={[14.4, 12.52, -0.6]}
              rotation={[-Math.PI / 2, 0.56, -Math.PI]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_224.geometry}
                material={plasticMat}
              />
            </group>
          </group>
          <group position={[0.03, 0, 29.45]} rotation={[0, 0, -Math.PI]}>
            <group position={[0, 0, 3.85]} rotation={[Math.PI / 2, 0, 0]} />
            <group position={[0, 0, 6.9]} rotation={[-Math.PI / 2, 0.48, 0]} />
            <group position={[0, 0, 3.65]} rotation={[0, 0, -Math.PI / 2]} />
            <group position={[5.5, 9.53, 0.3]} rotation={[0, 0, 2.83]} />
            <group position={[11, 0, 0.3]} rotation={[0, 0, 1.79]} />
            <group position={[5.5, -9.53, 0.3]} rotation={[0, 0, 0.74]} />
            <group position={[-5.5, -9.53, 0.3]} rotation={[0, 0, -0.31]} />
            <group position={[-11, 0, 0.3]} rotation={[0, 0, -1.35]} />
            <group position={[-5.5, 9.53, 0.3]} rotation={[0, 0, -2.4]} />
          </group>
          <group position={[-13, -13, -0.9]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              castShadow
              geometry={nodes.mesh_225.geometry}
              material={opaque}
            />
          </group>
          <group position={[-13, 13, -0.9]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              castShadow
              geometry={nodes.mesh_226.geometry}
              material={opaque}
            />
          </group>
          <group position={[13, -13, -0.9]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              castShadow
              geometry={nodes.mesh_227.geometry}
              material={opaque}
            />
          </group>
          <group position={[13, 13, -0.9]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              castShadow
              geometry={nodes.mesh_228.geometry}
              material={opaque}
            />
          </group>
          <group
            position={[19.9, 0, 56.55]}
            rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          >
            <group
              position={[0, -19.95, -19.2]}
              rotation={[Math.PI, Math.PI / 2, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_229.geometry}
                material={metalMaterial}
              />
            </group>
            <group position={[0, 19.83, -0.1]} rotation={[0, 0, Math.PI]}>
              <mesh
                castShadow
                geometry={nodes.mesh_230.geometry}
                material={metalMaterial}
              />
            </group>
            <group position={[19.9, -19.95, -38.3]} rotation={[Math.PI, 0, 0]}>
              <mesh
                castShadow
                geometry={nodes.mesh_231.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[19.9, -12.05, -36.25]}
              rotation={[1.42, 0, Math.PI / 2]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_232.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[19.9, -12.05, -33.05]}
              rotation={[0.56, 0, Math.PI / 2]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_233.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[19.9, 12.05, -36.25]}
              rotation={[2.16, 0, Math.PI / 2]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_234.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[19.9, 12.05, -33.05]}
              rotation={[1.84, 0, Math.PI / 2]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_235.geometry}
                material={opaque}
              />
            </group>
            <mesh
              castShadow
              geometry={nodes.mesh_236.geometry}
              material={metalMaterial}
            />
            <group position={[0, 22.65, -19.2]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                castShadow
                geometry={nodes.mesh_237.geometry}
                material={plasticMat}
              />
            </group>
            <group
              position={[-98.88, 25.6, 7.31]}
              rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            >
              <group position={[-0.84, 87.94, 26.15]}>
                <mesh
                  castShadow
                  geometry={nodes.mesh_238.geometry}
                  material={opaque}
                />
                <mesh
                  castShadow
                  geometry={nodes.mesh_239.geometry}
                  material={opaque}
                />
              </group>
              <group position={[7.31, 98.88, 19.2]} rotation={[0, 0, -Math.PI]}>
                <mesh
                  castShadow
                  geometry={nodes.mesh_240.geometry}
                  material={opaque}
                />
              </group>
              <group position={[10.31, 98.88, 23.05]}>
                <mesh
                  castShadow
                  geometry={nodes.mesh_241.geometry}
                  material={metalStatiniertMaterial}
                />
              </group>
              <group
                position={[-0.06, 86.28, 26.55]}
                rotation={[-Math.PI / 2, 0, Math.PI]}
              >
                <mesh
                  castShadow
                  geometry={nodes.mesh_242.geometry}
                  material={metalMaterial}
                />
                <mesh
                  castShadow
                  geometry={nodes.mesh_243.geometry}
                  material={metalMaterial}
                />
                <mesh
                  castShadow
                  geometry={nodes.mesh_244.geometry}
                  material={metalMaterial}
                />
                <mesh
                  castShadow
                  geometry={nodes.mesh_245.geometry}
                  material={metalMaterial}
                />
                <mesh
                  castShadow
                  geometry={nodes.mesh_246.geometry}
                  material={metalMaterial}
                />
                <mesh
                  castShadow
                  geometry={nodes.mesh_247.geometry}
                  material={metalMaterial}
                />
                <mesh
                  castShadow
                  geometry={nodes.mesh_248.geometry}
                  material={metalMaterial}
                />
                <mesh
                  castShadow
                  geometry={nodes.mesh_249.geometry}
                  material={metalMaterial}
                />
                <mesh
                  castShadow
                  geometry={nodes.mesh_250.geometry}
                  material={metalMaterial}
                />
                <group
                  position={[-7.22, -8.35, 12.6]}
                  rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_251.geometry}
                    material={metalMaterial}
                  />
                </group>
                <group
                  position={[-7.22, 8.35, 12.6]}
                  rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_252.geometry}
                    material={metalMaterial}
                  />
                </group>
                <group
                  position={[-27.27, -57.5, 12.6]}
                  rotation={[-Math.PI / 2, 0, 0]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_253.geometry}
                    material={metalMaterial}
                  />
                </group>
                <group
                  position={[-27.27, -57.5, 12.6]}
                  rotation={[-Math.PI / 2, 0, 0]}
                >
                  <mesh
                    castShadow
                    geometry={nodes.mesh_254.geometry}
                    material={metalMaterial}
                  />
                </group>
                <mesh
                  castShadow
                  geometry={nodes.mesh_255.geometry}
                  material={metalMaterial}
                />
              </group>
            </group>
            <group
              position={[0, -19.95, -19.15]}
              rotation={[-Math.PI / 2, 0, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_256.geometry}
                material={plasticMat}
              />
            </group>
            <group
              position={[-19.9, 2.55, -18.9]}
              rotation={[-Math.PI, Math.PI / 2, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_257.geometry}
                material={plasticMat}
              />
            </group>
            <group
              position={[19.9, 2.55, -19.15]}
              rotation={[-Math.PI, -Math.PI / 2, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_258.geometry}
                material={plasticMat}
              />
            </group>
            <group
              position={[16.9, -12.05, -38.3]}
              rotation={[0, Math.PI / 2, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_259.geometry}
                material={plasticMat}
              />
            </group>
            <group
              position={[-7.18, -18.45, -16.95]}
              rotation={[Math.PI, 0, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_260.geometry}
                material={metalStatiniertMaterial}
              />
            </group>
            <group
              position={[-18.4, 17.5, -34.67]}
              rotation={[Math.PI / 2, 0, Math.PI / 2]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_261.geometry}
                material={metalStatiniertMaterial}
              />
            </group>
            <group
              position={[-18.5, -1.3, -27.7]}
              rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            />
            <group
              position={[-5, -18.55, -27.7]}
              rotation={[-Math.PI, -Math.PI / 2, 0]}
            />
            <group
              position={[16.82, 21.7, -30.59]}
              rotation={[Math.PI, Math.PI / 2, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_262.geometry}
                material={opaque}
              />
              <mesh
                castShadow
                geometry={nodes.mesh_263.geometry}
                material={plasticMat}
              />
              <mesh
                castShadow
                geometry={nodes.mesh_264.geometry}
                material={metalStatiniertMaterial}
              />
              <mesh
                castShadow
                geometry={nodes.mesh_265.geometry}
                material={plasticMat}
              />
              <mesh
                castShadow
                geometry={nodes.mesh_266.geometry}
                material={plasticMat}
              />
            </group>
            <group
              position={[-9.4, 19.15, -37.8]}
              rotation={[Math.PI / 2, 0, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_267.geometry}
                material={metalStatiniertMaterial}
              />
              <mesh
                castShadow
                geometry={nodes.mesh_268.geometry}
                material={plasticMat}
              />
              <mesh
                castShadow
                geometry={nodes.mesh_269.geometry}
                material={plasticMat}
              />
              <mesh
                castShadow
                geometry={nodes.mesh_270.geometry}
                material={plasticMat}
              />
            </group>
            <group position={[0, 22.05, -38.3]} rotation={[Math.PI, 0, 0]}>
              <mesh
                castShadow
                geometry={nodes.mesh_271.geometry}
                material={plasticGloss}
              />
            </group>
            <group position={[-11.93, 22.05, -37.8]} rotation={[0, 0, Math.PI]}>
              <mesh
                castShadow
                geometry={nodes.mesh_272.geometry}
                material={plasticGloss}
              />
            </group>
            <group position={[-14.41, 19.77, -37.1]} rotation={[Math.PI, 0, 0]}>
              <mesh
                castShadow
                geometry={nodes.mesh_273.geometry}
                material={plasticMat}
              />
            </group>
            <group
              position={[-16.9, 22.05, -37.1]}
              rotation={[Math.PI / 2, 0, Math.PI / 2]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_274.geometry}
                material={plasticMat}
              />
            </group>
            <group
              position={[0, 0, -38.4]}
              rotation={[-Math.PI / 2, Math.PI / 2, 0]}
            >
              <group
                position={[1.3, 0, -6]}
                rotation={[-Math.PI, 0, Math.PI]}
              />
              <group
                position={[-17.47, 0.45, -15.75]}
                rotation={[Math.PI / 2, 0, Math.PI / 2]}
              />
              <group
                position={[-18.47, -0.65, -14.93]}
                rotation={[0, Math.PI / 6, 0]}
              />
              <group
                position={[12.05, -3.85, 18]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              />
              <group
                position={[12.05, -3.85, 17.9]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              >
                <group
                  position={[0.3, 0, 1.7]}
                  rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
                />
                <group
                  position={[0.3, 0, -1.5]}
                  rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
                />
              </group>
              <group
                position={[12.05, -5.35, 17.67]}
                rotation={[-Math.PI / 2, Math.PI / 3, -Math.PI]}
              />
              <group
                position={[-18.47, -0.65, -10.52]}
                rotation={[0, -Math.PI / 2, 0]}
              />
              <group
                position={[-12.05, -3.85, 18]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              />
              <group
                position={[-12.05, -3.85, 17.9]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              >
                <group
                  position={[0.3, 0, 1.7]}
                  rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
                />
                <group
                  position={[0.3, 0, -1.5]}
                  rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
                />
              </group>
              <group
                position={[14.3, -0.32, 16.6]}
                rotation={[0, -Math.PI / 2, 0]}
              />
              <group
                position={[9.8, -0.32, 16.6]}
                rotation={[0, -Math.PI / 2, 0]}
              />
              <group
                position={[-9.8, -0.32, 16.6]}
                rotation={[0, -Math.PI / 2, 0]}
              />
              <group
                position={[-14.3, -0.32, 16.6]}
                rotation={[0, -Math.PI / 2, 0]}
              />
              <group
                position={[12.05, -2.15, 17.67]}
                rotation={[-Math.PI / 2, Math.PI / 3, -Math.PI]}
              />
              <group
                position={[-12.05, -5.35, 17.67]}
                rotation={[-Math.PI / 2, Math.PI / 3, -Math.PI]}
              />
              <group
                position={[-12.05, -2.15, 17.67]}
                rotation={[-Math.PI / 2, Math.PI / 3, -Math.PI]}
              />
            </group>
            <group
              position={[-16.38, 17.95, -38.4]}
              rotation={[Math.PI / 2, 0, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_275.geometry}
                material={opaque}
              />
            </group>
            <group
              position={[16.9, 12.05, -38.3]}
              rotation={[0, Math.PI / 2, 0]}
            >
              <mesh
                castShadow
                geometry={nodes.mesh_276.geometry}
                material={plasticMat}
              />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload("/Limifyze_Digital_Twin_excl.gltf")
