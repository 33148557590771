/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { GLTF } from "three-stdlib"
import { MeshBasicMaterial, MeshPhysicalMaterial } from "three"

type GLTFResult = GLTF & {
  nodes: {
    mesh_0: THREE.Mesh
  }
  materials: {
    ["Acryl (klar)"]: THREE.MeshStandardMaterial
  }
}

export default function PayterWindow({
  ...props
}: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF(
    "/models/Limifyze_Digital_Twin_Payter-Window.gltf"
  ) as GLTFResult
  const glassMaterial = new MeshBasicMaterial({
    color: 0x004C97
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group rotation={[Math.PI / 2, 0, 0]} />
          <group position={[0.28, 0.12, 1.05]}>
            <group
              position={[-3.13, -0.12, 14.15]}
              rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            />
            <group
              position={[-18.78, -0.12, 30.28]}
              rotation={[Math.PI / 2, Math.PI / 2, 0]}
            />
          </group>
          <group
            position={[-18.5, 0, 18.15]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
          />
          <group
            position={[-19.7, -14.7, 22.88]}
            rotation={[0, 0, Math.PI / 2]}
          >
            <mesh
              geometry={nodes.mesh_0.geometry}
              material={glassMaterial}
            />
          </group>
          <group position={[-18.5, 0, 13.98]} rotation={[0, 0, Math.PI / 2]} />
          <group position={[-19.7, 0, 13.98]} rotation={[0, 0, Math.PI / 2]} />
          <group
            position={[-2.95, -23.98, 24.45]}
            rotation={[Math.PI, 0, -Math.PI]}
          >
            <group
              position={[-3.1, 23.98, 24.58]}
              rotation={[-Math.PI, 0, Math.PI]}
            />
            <group
              position={[-3.1, 23.98, 24.58]}
              rotation={[-Math.PI, 0, Math.PI]}
            />
            <group position={[0.1, 29.5, 0]} />
            <group
              position={[1, 30.25, 0.17]}
              rotation={[0, 0, -Math.PI / 3]}
            />
            <group
              position={[1, 30.25, -0.57]}
              rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
            />
            <group
              position={[1, 30.25, -0.33]}
              rotation={[Math.PI / 2, 0, 0]}
            />
            <group position={[0.1, 16.95, 0]} />
            <group position={[1, 17.7, 0.17]} rotation={[0, 0, -Math.PI / 3]} />
            <group
              position={[1, 17.7, -0.57]}
              rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
            />
            <group position={[1, 17.7, -0.33]} rotation={[Math.PI / 2, 0, 0]} />
          </group>
          <group position={[-11.57, 0, 3.7]} rotation={[0, 0, -Math.PI / 2]}>
            <group
              position={[-0.03, 0.75, 1.85]}
              rotation={[Math.PI / 2, 0, 0]}
            />
            <group position={[-0.03, 0, 0]} />
          </group>
          <group
            position={[-10.98, 0, 22.9]}
            rotation={[Math.PI / 2, 0.84, 0]}
          />
          <group
            position={[-20, 0, 30.88]}
            rotation={[Math.PI / 2, -Math.PI / 2, 0]}
          >
            <group
              position={[0, -0.73, 2.49]}
              rotation={[-Math.PI / 9, 0, 0]}
            />
            <group
              position={[1.19, -0.24, 2.99]}
              rotation={[-Math.PI / 9, 0, 0]}
            >
              <group position={[2.74, -0.8, 0.53]}>
                <group position={[-12.98, -5.49, -0.05]} />
                <group position={[-3.79, 0.31, -0.08]} />
                <group position={[-3.79, 0.31, -0.13]} />
                <group position={[-1.54, -11.49, -0.11]} />
                <group
                  position={[5.34, -5.67, -0.05]}
                  rotation={[-Math.PI, 0, Math.PI]}
                />
                <group
                  position={[4.46, -4.68, -0.13]}
                  rotation={[-Math.PI, 0, Math.PI]}
                />
              </group>
              <group position={[1.79, -1.36, 0.05]}>
                <group position={[-11.13, -4.24, -0.29]} />
                <group position={[-4.62, -12.22, 0.21]} />
              </group>
              <group
                position={[-0.56, 7.38, -15.09]}
                rotation={[-Math.PI, 0, Math.PI]}
              >
                <group position={[-7.11, -10.21, -14.46]} />
                <group position={[-3, -7.37, -14.25]}>
                  <group position={[0.32, -0.08, 0]} />
                  <group position={[-0.22, 0.03, -0.02]} />
                  <group
                    position={[0, 0.13, 0.01]}
                    rotation={[-Math.PI / 2, 0, Math.PI]}
                  />
                  <group position={[-0.32, -0.08, 0]} />
                  <group position={[-0.17, 0.03, -0.02]} />
                  <group position={[-0.13, 0.03, -0.02]} />
                  <group position={[-0.08, 0.03, -0.02]} />
                  <group position={[-0.03, 0.03, -0.02]} />
                  <group position={[0.03, 0.03, -0.02]} />
                  <group position={[0.08, 0.03, -0.02]} />
                  <group position={[0.13, 0.03, -0.02]} />
                  <group position={[0.17, 0.03, -0.02]} />
                  <group position={[0.22, 0.03, -0.02]} />
                </group>
                <group position={[0.61, -5.76, -14.25]}>
                  <group position={[1.32, -0.07, 0]} />
                  <group position={[-1.23, 0.04, -0.02]} />
                  <group
                    position={[0, 0.16, 0.01]}
                    rotation={[-Math.PI / 2, 0, Math.PI]}
                  />
                  <group position={[-1.32, -0.07, 0]} />
                  <group position={[0.93, 0.04, -0.02]} />
                  <group position={[-0.98, 0.04, -0.02]} />
                  <group position={[0.88, 0.04, -0.02]} />
                  <group position={[0.28, 0.04, -0.02]} />
                  <group position={[1.13, 0.04, -0.02]} />
                  <group position={[0.63, 0.04, -0.02]} />
                  <group position={[1.02, 0.04, -0.02]} />
                  <group position={[0.98, 0.04, -0.02]} />
                  <group position={[-0.22, 0.04, -0.02]} />
                  <group position={[1.08, 0.04, -0.02]} />
                  <group position={[-0.63, 0.04, -0.02]} />
                  <group position={[0.57, 0.04, -0.02]} />
                  <group position={[-0.03, 0.04, -0.02]} />
                  <group position={[-0.28, 0.04, -0.02]} />
                  <group position={[-0.73, 0.04, -0.02]} />
                  <group position={[0.13, 0.04, -0.02]} />
                  <group position={[-0.93, 0.04, -0.02]} />
                  <group position={[0.17, 0.04, -0.02]} />
                  <group position={[-0.82, 0.04, -0.02]} />
                  <group position={[-0.38, 0.04, -0.02]} />
                  <group position={[-1.08, 0.04, -0.02]} />
                  <group position={[0.47, 0.04, -0.02]} />
                  <group position={[-1.17, 0.04, -0.02]} />
                  <group position={[-0.13, 0.04, -0.02]} />
                  <group position={[-0.32, 0.04, -0.02]} />
                  <group position={[0.77, 0.04, -0.02]} />
                  <group position={[0.82, 0.04, -0.02]} />
                  <group position={[-0.88, 0.04, -0.02]} />
                  <group position={[-1.02, 0.04, -0.02]} />
                  <group position={[0.32, 0.04, -0.02]} />
                  <group position={[0.73, 0.04, -0.02]} />
                  <group position={[0.68, 0.04, -0.02]} />
                  <group position={[0.22, 0.04, -0.02]} />
                  <group position={[-0.52, 0.04, -0.02]} />
                  <group position={[-0.43, 0.04, -0.02]} />
                  <group position={[0.52, 0.04, -0.02]} />
                  <group position={[-0.77, 0.04, -0.02]} />
                  <group position={[-0.47, 0.04, -0.02]} />
                  <group position={[0.08, 0.04, -0.02]} />
                  <group position={[-1.13, 0.04, -0.02]} />
                  <group position={[0.43, 0.04, -0.02]} />
                  <group position={[0.38, 0.04, -0.02]} />
                  <group position={[-0.17, 0.04, -0.02]} />
                  <group position={[-0.08, 0.04, -0.02]} />
                  <group position={[-0.68, 0.04, -0.02]} />
                  <group position={[1.23, 0.04, -0.02]} />
                  <group position={[1.17, 0.04, -0.02]} />
                  <group position={[0.03, 0.04, -0.02]} />
                  <group position={[-0.57, 0.04, -0.02]} />
                </group>
                <group
                  position={[0.79, -9.97, -14.12]}
                  rotation={[0, 0, -Math.PI]}
                />
                <group
                  position={[-3.97, -9.97, -14.12]}
                  rotation={[0, 0, -Math.PI]}
                />
                <group
                  position={[-2.03, -9.97, -14.12]}
                  rotation={[0, 0, -Math.PI]}
                />
                <group
                  position={[3.29, -8.21, -14.15]}
                  rotation={[Math.PI / 2, 0, Math.PI]}
                />
                <group
                  position={[5.29, -7.76, -13.89]}
                  rotation={[0, 0, -Math.PI]}
                />
                <group position={[7.29, -8, -13.75]} />
                <group position={[-7.14, -10.29, -14.86]} />
                <group
                  position={[-1.92, -4.14, -14.3]}
                  rotation={[0, 0, Math.PI / 2]}
                />
                <group
                  position={[-0.92, -4.14, -14.3]}
                  rotation={[0, 0, Math.PI / 2]}
                />
                <group
                  position={[0.08, -4.14, -14.3]}
                  rotation={[0, 0, Math.PI / 2]}
                />
                <group
                  position={[1.08, -4.14, -14.3]}
                  rotation={[0, 0, Math.PI / 2]}
                />
                <group
                  position={[2.08, -4.14, -14.3]}
                  rotation={[0, 0, Math.PI / 2]}
                />
                <group position={[-0.58, -9.28, -13.99]}>
                  <group position={[-0.38, 0, -0.15]} />
                  <group
                    position={[-0.38, 0, -0.15]}
                    rotation={[0, 0, Math.PI]}
                  />
                  <group position={[-0.38, -0.04, 0]} />
                  <group
                    position={[-0.38, -0.04, -0.05]}
                    rotation={[0, 0, Math.PI]}
                  />
                  <group position={[0, 0, 0.06]} />
                  <group position={[-0.13, 0, -0.15]} />
                  <group position={[0.13, 0, -0.15]} />
                  <group position={[0.38, 0, -0.15]} />
                  <group
                    position={[-0.13, 0, -0.15]}
                    rotation={[0, 0, Math.PI]}
                  />
                  <group
                    position={[0.13, 0, -0.15]}
                    rotation={[0, 0, Math.PI]}
                  />
                  <group
                    position={[0.38, 0, -0.15]}
                    rotation={[0, 0, Math.PI]}
                  />
                  <group position={[-0.13, -0.04, 0]} />
                  <group position={[0.13, -0.04, 0]} />
                  <group position={[0.38, -0.04, 0]} />
                  <group
                    position={[-0.13, -0.04, -0.05]}
                    rotation={[0, 0, Math.PI]}
                  />
                  <group
                    position={[0.13, -0.04, -0.05]}
                    rotation={[0, 0, Math.PI]}
                  />
                  <group
                    position={[0.38, -0.04, -0.05]}
                    rotation={[0, 0, Math.PI]}
                  />
                </group>
                <group
                  position={[-5.56, -9.97, -14.12]}
                  rotation={[0, 0, -Math.PI]}
                />
              </group>
            </group>
            <group position={[0, -0.35, 3.53]} rotation={[2.79, 0, 0]} />
          </group>
          <group
            position={[13.45, 0, 8.02]}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
          >
            <group
              position={[-11.5, 2.9, 6.45]}
              rotation={[-Math.PI / 2, 0, 0]}
            />
            <group
              position={[-11.5, 4.86, 5.75]}
              rotation={[Math.PI / 2, -Math.PI / 2, 0]}
            />
            <group position={[0, 2.38, 6.75]} />
            <group
              position={[-18.45, 8.98, 6.7]}
              rotation={[-Math.PI / 2, Math.PI / 6, 0]}
            />
            <group
              position={[-11.5, 0.88, 5.75]}
              rotation={[Math.PI / 2, -Math.PI / 6, 0]}
            />
            <group
              position={[-18.45, -0.82, 6.7]}
              rotation={[-Math.PI / 2, Math.PI / 6, 0]}
            />
            <group
              position={[0, 8.98, 6.7]}
              rotation={[-Math.PI / 2, Math.PI / 6, 0]}
            />
            <group
              position={[0, -0.82, 6.7]}
              rotation={[-Math.PI / 2, Math.PI / 6, 0]}
            />
            <group
              position={[18.45, 8.98, 6.7]}
              rotation={[-Math.PI / 2, Math.PI / 6, 0]}
            />
            <group
              position={[18.45, -0.82, 6.7]}
              rotation={[-Math.PI / 2, Math.PI / 6, 0]}
            />
            <group
              position={[0, 4.07, 6.55]}
              rotation={[-Math.PI / 2, Math.PI / 2, 0]}
            />
          </group>
          <group
            position={[19.9, 0, 18.4]}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
          >
            <group
              position={[16.9, -14.25, 0]}
              rotation={[-Math.PI / 2, 0, 0]}
            />
            <group
              position={[0.05, -14.25, 0]}
              rotation={[-Math.PI / 2, 0.29, 0]}
            />
            <group
              position={[-16.9, -14.25, 0]}
              rotation={[-Math.PI / 2, 0, 0]}
            />
            <group
              position={[19.9, -14.25, -36.7]}
              rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            />
            <group
              position={[-19.9, -14.25, -36.7]}
              rotation={[-1.68, 0, -Math.PI / 2]}
            />
            <group
              position={[19.9, 12.2, -36.7]}
              rotation={[3.09, 0, Math.PI / 2]}
            />
            <group
              position={[-19.9, 12.2, -36.7]}
              rotation={[-1.2, 0, -Math.PI / 2]}
            />
            <group
              position={[16.9, 8.1, 0]}
              rotation={[Math.PI / 2, 1.05, -Math.PI]}
            />
            <group
              position={[-16.9, 8.1, 0]}
              rotation={[Math.PI / 2, -0.94, -Math.PI]}
            />
            <group position={[0, 8.1, 0]} rotation={[-Math.PI / 2, -1.37, 0]} />
          </group>
          <group
            position={[0.03, 0, 33.8]}
            rotation={[Math.PI, 0, Math.PI / 2]}
          >
            <group position={[0, -0.68, 0.4]} rotation={[-Math.PI / 2, 0, 0]} />
            <group
              position={[14.4, 12.52, -0.6]}
              rotation={[-Math.PI / 2, 0.56, -Math.PI]}
            />
          </group>
          <group position={[0.03, 0, 29.45]} rotation={[0, 0, -Math.PI]}>
            <group position={[0, 0, 3.85]} rotation={[Math.PI / 2, 0, 0]} />
            <group position={[0, 0, 6.9]} rotation={[-Math.PI / 2, 0.48, 0]} />
            <group position={[0, 0, 3.65]} rotation={[0, 0, -Math.PI / 2]} />
            <group position={[5.5, 9.53, 0.3]} rotation={[0, 0, 2.83]} />
            <group position={[11, 0, 0.3]} rotation={[0, 0, 1.79]} />
            <group position={[5.5, -9.53, 0.3]} rotation={[0, 0, 0.74]} />
            <group position={[-5.5, -9.53, 0.3]} rotation={[0, 0, -0.31]} />
            <group position={[-11, 0, 0.3]} rotation={[0, 0, -1.35]} />
            <group position={[-5.5, 9.53, 0.3]} rotation={[0, 0, -2.4]} />
          </group>
          <group position={[-13, -13, -0.9]} rotation={[Math.PI / 2, 0, 0]} />
          <group position={[-13, 13, -0.9]} rotation={[Math.PI / 2, 0, 0]} />
          <group position={[13, -13, -0.9]} rotation={[Math.PI / 2, 0, 0]} />
          <group position={[13, 13, -0.9]} rotation={[Math.PI / 2, 0, 0]} />
          <group
            position={[19.9, 0, 56.55]}
            rotation={[-Math.PI / 2, Math.PI / 2, 0]}
          >
            <group
              position={[0, -19.95, -19.2]}
              rotation={[Math.PI, Math.PI / 2, 0]}
            />
            <group position={[0, 19.83, -0.1]} rotation={[0, 0, Math.PI]} />
            <group
              position={[19.9, -19.95, -38.3]}
              rotation={[Math.PI, 0, 0]}
            />
            <group
              position={[19.9, -12.05, -36.25]}
              rotation={[1.42, 0, Math.PI / 2]}
            />
            <group
              position={[19.9, -12.05, -33.05]}
              rotation={[0.56, 0, Math.PI / 2]}
            />
            <group
              position={[19.9, 12.05, -36.25]}
              rotation={[2.16, 0, Math.PI / 2]}
            />
            <group
              position={[19.9, 12.05, -33.05]}
              rotation={[1.84, 0, Math.PI / 2]}
            />
            <group
              position={[0, 22.65, -19.2]}
              rotation={[Math.PI / 2, 0, 0]}
            />
            <group
              position={[-98.88, 25.6, 7.31]}
              rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            >
              <group position={[-0.84, 87.94, 26.15]} />
              <group
                position={[7.31, 98.88, 19.2]}
                rotation={[0, 0, -Math.PI]}
              />
              <group position={[10.31, 98.88, 23.05]} />
              <group
                position={[-0.06, 86.28, 26.55]}
                rotation={[-Math.PI / 2, 0, Math.PI]}
              >
                <group
                  position={[-7.22, -8.35, 12.6]}
                  rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                />
                <group
                  position={[-7.22, 8.35, 12.6]}
                  rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                />
                <group
                  position={[-27.27, -57.5, 12.6]}
                  rotation={[-Math.PI / 2, 0, 0]}
                />
                <group
                  position={[-27.27, -57.5, 12.6]}
                  rotation={[-Math.PI / 2, 0, 0]}
                />
              </group>
            </group>
            <group
              position={[0, -19.95, -19.15]}
              rotation={[-Math.PI / 2, 0, 0]}
            />
            <group
              position={[-19.9, 2.55, -18.9]}
              rotation={[-Math.PI, Math.PI / 2, 0]}
            />
            <group
              position={[19.9, 2.55, -19.15]}
              rotation={[-Math.PI, -Math.PI / 2, 0]}
            />
            <group
              position={[16.9, -12.05, -38.3]}
              rotation={[0, Math.PI / 2, 0]}
            />
            <group
              position={[-7.18, -18.45, -16.95]}
              rotation={[Math.PI, 0, 0]}
            />
            <group
              position={[-18.4, 17.5, -34.67]}
              rotation={[Math.PI / 2, 0, Math.PI / 2]}
            />
            <group
              position={[-18.5, -1.3, -27.7]}
              rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            />
            <group
              position={[-5, -18.55, -27.7]}
              rotation={[-Math.PI, -Math.PI / 2, 0]}
            />
            <group
              position={[16.82, 21.7, -30.59]}
              rotation={[Math.PI, Math.PI / 2, 0]}
            />
            <group
              position={[-9.4, 19.15, -37.8]}
              rotation={[Math.PI / 2, 0, 0]}
            />
            <group position={[0, 22.05, -38.3]} rotation={[Math.PI, 0, 0]} />
            <group
              position={[-11.93, 22.05, -37.8]}
              rotation={[0, 0, Math.PI]}
            />
            <group
              position={[-14.41, 19.77, -37.1]}
              rotation={[Math.PI, 0, 0]}
            />
            <group
              position={[-16.9, 22.05, -37.1]}
              rotation={[Math.PI / 2, 0, Math.PI / 2]}
            />
            <group
              position={[0, 0, -38.4]}
              rotation={[-Math.PI / 2, Math.PI / 2, 0]}
            >
              <group
                position={[1.3, 0, -6]}
                rotation={[-Math.PI, 0, Math.PI]}
              />
              <group
                position={[-17.47, 0.45, -15.75]}
                rotation={[Math.PI / 2, 0, Math.PI / 2]}
              />
              <group
                position={[-18.47, -0.65, -14.93]}
                rotation={[0, Math.PI / 6, 0]}
              />
              <group
                position={[12.05, -3.85, 18]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              />
              <group
                position={[12.05, -3.85, 17.9]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              >
                <group
                  position={[0.3, 0, 1.7]}
                  rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
                />
                <group
                  position={[0.3, 0, -1.5]}
                  rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
                />
              </group>
              <group
                position={[12.05, -5.35, 17.67]}
                rotation={[-Math.PI / 2, Math.PI / 3, -Math.PI]}
              />
              <group
                position={[-18.47, -0.65, -10.52]}
                rotation={[0, -Math.PI / 2, 0]}
              />
              <group
                position={[-12.05, -3.85, 18]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              />
              <group
                position={[-12.05, -3.85, 17.9]}
                rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
              >
                <group
                  position={[0.3, 0, 1.7]}
                  rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
                />
                <group
                  position={[0.3, 0, -1.5]}
                  rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
                />
              </group>
              <group
                position={[14.3, -0.32, 16.6]}
                rotation={[0, -Math.PI / 2, 0]}
              />
              <group
                position={[9.8, -0.32, 16.6]}
                rotation={[0, -Math.PI / 2, 0]}
              />
              <group
                position={[-9.8, -0.32, 16.6]}
                rotation={[0, -Math.PI / 2, 0]}
              />
              <group
                position={[-14.3, -0.32, 16.6]}
                rotation={[0, -Math.PI / 2, 0]}
              />
              <group
                position={[12.05, -2.15, 17.67]}
                rotation={[-Math.PI / 2, Math.PI / 3, -Math.PI]}
              />
              <group
                position={[-12.05, -5.35, 17.67]}
                rotation={[-Math.PI / 2, Math.PI / 3, -Math.PI]}
              />
              <group
                position={[-12.05, -2.15, 17.67]}
                rotation={[-Math.PI / 2, Math.PI / 3, -Math.PI]}
              />
            </group>
            <group
              position={[-16.38, 17.95, -38.4]}
              rotation={[Math.PI / 2, 0, 0]}
            />
            <group
              position={[16.9, 12.05, -38.3]}
              rotation={[0, Math.PI / 2, 0]}
            />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload("/Limifyze_Digital_Twin_Payter-Window.gltf")
