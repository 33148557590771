import * as React from "react"
import { Canvas } from "@react-three/fiber"
import Machine from "./Machine"
import { createGlobalStyle } from "styled-components"
import CameraControls from "./CameraControls"
import { PCFSoftShadowMap } from "three"
import Overlay from "./Overlay"

const GlobalStyle = createGlobalStyle`
  body {
    min-height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #F7F7F7;
  }

  html {
    height: 100%;
    width: 100%;
    font-family: sans-serif;
  }

  canvas {
    width: 100vw;
    height: 100vh;
  }

`

export default function App() {
  const [isDoorOpen, setIsDoorOpen] = React.useState(false)
  return (
    <>
      <GlobalStyle />
      <Canvas
        flat
        linear
        shadows={{ type: PCFSoftShadowMap, enabled: true, autoUpdate: true }}
      >
        <CameraControls />
        <Machine isDoorOpen={isDoorOpen} />
      </Canvas>
      <Overlay onOpenDoor={() => setIsDoorOpen(!isDoorOpen)} />
    </>
  )
}
