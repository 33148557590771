/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { GLTF } from "three-stdlib"
import { MeshPhysicalMaterial } from "three"

type GLTFResult = GLTF & {
  nodes: {
    mesh_0: THREE.Mesh
    mesh_1: THREE.Mesh
    mesh_2: THREE.Mesh
    mesh_3: THREE.Mesh
    mesh_4: THREE.Mesh
    mesh_5: THREE.Mesh
    mesh_6: THREE.Mesh
    mesh_7: THREE.Mesh
    mesh_8: THREE.Mesh
    mesh_9: THREE.Mesh
    mesh_10: THREE.Mesh
    mesh_11: THREE.Mesh
    mesh_12: THREE.Mesh
    mesh_13: THREE.Mesh
    mesh_14: THREE.Mesh
    mesh_15: THREE.Mesh
    mesh_16: THREE.Mesh
    mesh_17: THREE.Mesh
    mesh_18: THREE.Mesh
    mesh_19: THREE.Mesh
    mesh_20: THREE.Mesh
    mesh_21: THREE.Mesh
    mesh_22: THREE.Mesh
    mesh_23: THREE.Mesh
    mesh_24: THREE.Mesh
    mesh_25: THREE.Mesh
    mesh_26: THREE.Mesh
    mesh_27: THREE.Mesh
    mesh_28: THREE.Mesh
  }
  materials: {}
}

export default function DoorModel({
  ...props
}: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF(
    "/models/Limifyze_Digital_Twin_Door.gltf"
  ) as GLTFResult
  const glassMaterial = new MeshPhysicalMaterial({
    metalness: 0.9,
    roughness: 0.05,
    envMapIntensity: 0.9,
    clearcoat: 1,
    transparent: true,
    opacity: 0.5,
    reflectivity: 0.2,
    side: THREE.BackSide,
    emissive: 0x000000,
    color: 0xffffff,
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_0.geometry}
        material={nodes.mesh_0.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_1.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_2.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_3.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_4.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_5.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_6.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_7.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_8.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_9.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_10.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_11.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_12.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_13.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_14.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_15.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_16.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_17.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_18.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_19.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_20.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_21.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_22.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_23.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_24.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_25.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_26.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_27.geometry}
        material={glassMaterial}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_28.geometry}
        material={glassMaterial}
      />
    </group>
  )
}

useGLTF.preload("/Limifyze_Digital_Twin_Door.gltf")
